import { Injectable } from "@angular/core";
import
{
  ShopProductFamily,
  ShopProduct,
  Order,
  Cart,
  OrderItem,
  Wishlist,
  CartItem
} from "@dto";
import
{
  SearchResult,
  Record,
} from "@idto";
import { NavigationEnd } from "@angular/router";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { AnalyticsBaseService } from "./abstract/analytics-base.service";
import { GoogleAdsAnalyticsService } from "./google-ads-analytics.service";
import { environment } from "environments/environment";

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class Ga4AnalyticsService extends AnalyticsBaseService
{
  constructor(
    public urlTranslateService: UrlTranslateService,
    public googleAdsAnalyticsService: GoogleAdsAnalyticsService
  ) { super(); }

  public getName(): string
  {
    return 'Ga4AnalyticsService';
  }

  public isEnabled(): boolean
  {
    return (typeof gtag !== 'undefined');
  }

  public async initialize(): Promise<void>
  {
    return this.googleAdsAnalyticsService.initialize();
  }

  public async registerUserID(userID: string): Promise<void>
  {
    gtag('set',
      {
        'user_id': userID
      });
  }

  //#region Generic events
  public async registerEvent(eventCategory: string, eventAction: string, eventLabel: string = "", eventValue: any = null, eventNonInteraction: boolean = false): Promise<void>
  {
    gtag("event", eventAction, {
      send_to: environment.analytics.googleGA4PropertyID,
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
      non_interaction: eventNonInteraction,
    });
  }

  public async registerPageView(event: NavigationEnd): Promise<void>
  {
    gtag('event', 'page_view', {
      send_to: environment.analytics.googleGA4PropertyID,
      page_path: event.urlAfterRedirects
    });
  }
  //#endregion

  //#region Shop flow events
  public async registerProductListView(result: SearchResult, list: string): Promise<void>
  {
    const items = result.records.map(item =>
    {
      return {
        item_id: item.productGroupID,
        item_name: item.productName,
        item_category: item.productType,
        //price: Number(item.headerPromotionPrice),
      }
    })

    gtag("event", "view_item_list", {
      send_to: environment.analytics.googleGA4PropertyID,
      item_list_id: list,
      items
    });
  }

  public async registerProductPageView(product: ShopProductFamily): Promise<void>
  {
    gtag("event", "view_item", {
      send_to: environment.analytics.googleGA4PropertyID,
      //currency: "PLN",
      //value: Number(product.promotionPrice),
      items: [
        {
          item_id: product.familyCode,
          item_name: product.name,
          item_category: product.familyType,
          //price: Number(product.promotionPrice),
        }
      ]
    });
  }

  public async registerSelectItem(product: Record, list: string): Promise<void>
  {
    gtag("event", "select_item", {
      send_to: environment.analytics.googleGA4PropertyID,
      item_list_id: list,
      items: [
        {
          item_id: product.productGroupID,
          item_name: product.productName
        }
      ]
    });
  }

  public async registerWishlistAdd(wishlist: Wishlist, profileFamilySku: string): Promise<void>
  {
    const addedItem = wishlist.products.find(product => product.familyCode === profileFamilySku);

    if (addedItem)
    {
      gtag("event", "add_to_wishlist", {
        send_to: environment.analytics.googleGA4PropertyID,
        //currency: "PLN",
        //value: Number(addedItem.promotionPrice),
        items: [
          {
            item_id: addedItem.familyCode,
            item_name: addedItem.name,
            item_category: addedItem.familyType,
            //price: Number(addedItem.promotionPrice),
          }
        ]
      });
    }
  }
  //#endregion

  //#region Transaction
  public async registerCartAdd(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
    gtag("event", "add_to_cart", {
      send_to: environment.analytics.googleAdsAndGA4,
      currency: "PLN",
      value: quantity * parseFloat((product.promotionPrice / 1.23).toFixed(2)),
      items: [this.getShopProductItem(product, quantity)]
    });
  }

  public async registerCartRemove(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
    gtag("event", "remove_from_cart", {
      send_to: environment.analytics.googleGA4PropertyID,
      currency: "PLN",
      value: quantity * parseFloat((product.promotionPrice / 1.23).toFixed(2)),
      items: [this.getShopProductItem(product, quantity)]
    });
  }

  public async registerCartView(cart: Cart): Promise<void>
  {
    const items = cart.items.map(item => this.getCartItem(item));

    gtag("event", "view_cart", {
      send_to: environment.analytics.googleGA4PropertyID,
      currency: "PLN",
      value: parseFloat((cart.grandPromotionTotalBrutto / 1.23).toFixed(2)),
      items
    });
  }

  public async registerBeginCheckout(cart: Cart): Promise<void>
  {
    const items = cart.items.map(item => this.getCartItem(item));

    gtag("event", "begin_checkout", {
      send_to: environment.analytics.googleAdsAndGA4,
      currency: "PLN",
      value: parseFloat((cart.grandPromotionTotalBrutto / 1.23).toFixed(2)),
      items
    });

    gtag("event", "add_payment_info", {
      send_to: environment.analytics.googleGA4PropertyID,
      currency: "PLN",
      value: parseFloat((cart.grandPromotionTotalBrutto / 1.23).toFixed(2)),
      payment_type: cart.selectedPaymentMethod.name,
      items
    });

    gtag("event", "add_shipping_info", {
      send_to: environment.analytics.googleGA4PropertyID,
      currency: "PLN",
      value: parseFloat((cart.grandPromotionTotalBrutto / 1.23).toFixed(2)),
      shipping_tier: cart.selectedDeliveryMethod.name,
      items
    });
  }

  public async registerTransaction(order: Order): Promise<void>
  {
    let coupon = order.discounts.map((o) => o.couponCode).join(",");

    let subItems = [];

    const items = order.items.map(item =>
    {
      if (item.subItems && item.subItems.length)
      {
        item.subItems.map(o => 
        {
          subItems.push(this.getOrderItem(o, coupon));
        });
      }

      return this.getOrderItem(item, coupon);
    });

    const allItems = items.concat(subItems);

    gtag("event", "conversion", {
      send_to: environment.analytics.googleAdsConversionID,
      currency: "PLN",
      transaction_id: order.iD,
      value: (order.productsTotalBrutto / 1.23).toFixed(2),
      items: allItems
    });

    gtag("event", "purchase", {
      send_to: environment.analytics.googleAdsAndGA4,
      currency: "PLN",
      transaction_id: order.iD,
      value: parseFloat((order.productsTotalBrutto / 1.23).toFixed(2)),
      tax: 0,
      shipping: parseFloat((order.shipmentTotalBrutto / 1.23).toFixed(2)),
      coupon: coupon,
      items: allItems
    });
  }
  //#endregion

  //#region Basket steps flow
  public async registerCheckoutRegistrationEnter(): Promise<void>
  {
    gtag('event', 'checkout',
      {
        send_to: environment.analytics.googleGA4PropertyID,
        step: 2,
        non_interaction: true
      });
  }

  public async registerCheckoutCustomerDataEnter(): Promise<void>
  {

    gtag('event', 'checkout', {
      send_to: environment.analytics.googleGA4PropertyID,
      step: 3,
      non_interaction: true
    });


  }

  public async registerCheckoutReviewEnter(): Promise<void>
  {
    gtag('event', 'checkout', {
      send_to: environment.analytics.googleGA4PropertyID,
      step: 4,
      non_interaction: true
    });
  }

  public async registerCheckoutOrderPlacedEnter(): Promise<void>
  {
    gtag('event', 'checkout', {
      send_to: environment.analytics.googleGA4PropertyID,
      step: 5,
      non_interaction: true
    });
  }
  //#endregion

  //#region Subscription
  // public async registerCustomerNewsletterSubscription(): Promise<void>
  // {

  // }
  // public async registerCustomerNewsletterUnsubscription(): Promise<void>
  // {

  // }
  //#endregion


  //#region Private helper methods
  public getCartItem(orderItem: CartItem): any
  {
    return this.getShopProductItem(orderItem.product, orderItem.quantity);
  }
  public getOrderItem(orderItem: OrderItem, coupon: string): any
  {
    let productPart = this.getShopProductItem(orderItem.product, orderItem.quantity);

    productPart.price = parseFloat((orderItem.unitDiscountedPriceBrutto / 1.23).toFixed(2)),
      productPart.discount = parseFloat(((orderItem.unitOriginalPriceBrutto - orderItem.unitDiscountedPriceBrutto) / 1.23).toFixed(2)),
      productPart.coupon = coupon;

    return productPart;
  }
  public getShopProductItem(product: ShopProduct, quantity: number): any
  {
    return {
      id: product.code,
      item_id: product.code,
      item_name: product.name,
      item_category: product.productType,
      item_variant: product.option,
      price: parseFloat((product.promotionPrice / 1.23).toFixed(2)),
      quantity: quantity,
      google_business_vertical: 'retail'
    }
  }
  //#endregion

  public async registerUserData(email: string, phone: string): Promise<void>
  {
    gtag('set', 'user_data', {
      email: email,
      phone_number: phone
    });
  }
}