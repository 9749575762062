import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ConfigService
{
  public static staticImageUrl: string = ''

  public readonly BaseUrl: string;
  public readonly ApiUrl: string;
  public readonly ContentUrl: string;
  public readonly ImageUrl: string;
  public readonly BlogUrl: string;
  public readonly ShopID: string;
  public readonly LowDomain: string;
  public readonly MainDomain: string;
  public readonly TopDomain: string;
  public readonly Alldomain: string;

  public static readonly TopDomainMap = new Map([
    ['localhost', 100],
    ['development', 200],
    ['feature', 100],
    ['sgm', 200],
    ['spo', 200],
    ['swr', 200],
    ['swd', 200],
    ['sta', 200],
    ['sjn', 200],
    ['ssc', 200],
    ['sko', 200],
    ['ska', 200],
  ]);

  public static readonly LowDomainMap = new Map([
    ['pl', 200],
    ['cz', 201],
    ['de', 202],
    ['be', 203],
    ['bg', 204],
    ['dk', 205],
    ['ee', 206],
    ['ie', 207],
    ['el', 208],
    ['es', 209],
    ['fr', 210],
    ['hr', 211],
    ['it', 212],
    ['cy', 213],
    ['lv', 214],
    ['lt', 215],
    ['lu', 216],
    ['hu', 217],
    ['mt', 218],
    ['nl', 219],
    ['at', 220],
    ['pt', 221],
    ['ro', 222],
    ['si', 223],
    ['sk', 224],
    ['fi', 225],
    ['se', 226],
    ['tr', 227],
    ['me', 228],
    ['uk', 229],
    ['is', 230],
    ['li', 231],
    ['no', 232],
    ['ch', 233],
  ]);

  constructor()
  {
    const host = window.location.host;
    const hostParts = host.split(".").map(o => o.split(":")[0]);
    const onlyDomain = hostParts.filter(o => o.length > 3);

    this.LowDomain = hostParts[hostParts.length - 1];
    this.MainDomain = onlyDomain[onlyDomain.length - 1];
    this.TopDomain = hostParts[0];
    this.Alldomain = hostParts.slice(0, -2).join('-');

    if (ConfigService.TopDomainMap.has(this.TopDomain))
    {
      this.ShopID = ConfigService.TopDomainMap.get(this.TopDomain).toString();
    }
    else if (ConfigService.LowDomainMap.has(this.LowDomain))
    {
      this.ShopID = ConfigService.LowDomainMap.get(this.LowDomain).toString();
    }

    // if (this.Alldomain == "development")
    // {
    //   this.Alldomain = "";
    // }
    // if (this.MainDomain == "development")
    // {
    //   this.MainDomain = "";
    // }

    if (this.LowDomain == 'localhost' || this.TopDomain == 'feature')
    {
      this.LowDomain = "pl";
      // this.TopDomain = "";
      // this.Alldomain = "";

      this.TopDomain = "development";
      this.Alldomain = "development";
      this.MainDomain = "arte";
    }

    if (this.Alldomain.length > 0)
    {
      this.Alldomain += "-";
    }

    this.BaseUrl = environment.baseUrl;

    let apiUrl = environment.apiUrl.replace('{loc}', this.LowDomain);
    let contentUrl = environment.contentUrl.replace('{loc}', this.LowDomain);
    let imageUrl = environment.imageUrl.replace('{loc}', this.LowDomain);
    let blogUrl = environment.blogUrl.replace('{loc}', this.LowDomain);

    apiUrl = apiUrl.replace('{domain}', this.MainDomain);
    contentUrl = contentUrl.replace('{domain}', this.MainDomain);
    imageUrl = imageUrl.replace('{domain}', this.MainDomain);
    blogUrl = blogUrl.replace('{domain}', this.MainDomain);

    this.ApiUrl = apiUrl.replace('{subdomain-}', this.Alldomain);
    this.ContentUrl = contentUrl.replace('{subdomain-}', this.Alldomain);
    this.BlogUrl = blogUrl.replace('{subdomain-}', this.Alldomain);
    this.ImageUrl = imageUrl.replace('{subdomain-}', this.Alldomain);
    ConfigService.staticImageUrl = this.ImageUrl;
  }
}
